<template>
  <v-container>
    <h1>{{ verb }} Group</h1>
    <v-sheet color="white" elevation="1" class="px-5 mt-4 rounded">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="form.institute_id"
              :disabled="loading"
              :items="institutes"
              :loading="instituteLoading"
              :rules="rules.institute"
              :search-input.sync="search"
              label="Institute"
              item-text="name"
              item-value="id"
              cache-items
              flat
              hide-no-data
            ></v-autocomplete>
            <v-text-field
              v-model="form.name"
              :counter="30"
              :disabled="loading"
              :rules="rules.name"
              label="Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.grade"
              :disabled="loading"
              :rules="rules.grade"
              label="grade"
              type="number"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.group_code"
              v-if="dataId"
              :counter="5"
              :disabled="loading"
              :rules="rules.group_code"
              label="Group Code"
              required
            ></v-text-field>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="success"
              class="mt-2"
              type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Group from "@/services/group";
import Institute from "@/services/institute";

export default {
  name: "Group.Form",
  data() {
    return {
      valid: false,
      form: {
        institute_id: "",
        name: "",
        grade: 1,
        group_code: null
      },
      institutes: [],
      rules: {
        institute: [v => !!v || "Institute is required"],
        name: [
          v => !!v || "Name is required",
          v =>
            (v.length >= 5 && v.length <= 30) ||
            "Name must be greater than 5 and less then 30",
          v =>
            new RegExp(/^\s*\w+(?:[^\w,]+\w+)*[^,\w]*$/i).test(v) ||
            "Name must only contain alphanumeric and whitespace characters"
        ],
        grade: [
          v => !!v || "Grade is required",
          v =>
            (parseInt(v) >= 1 && parseInt(v) <= 12) ||
            "Grade must be greater than 1 and less than 12"
        ],
        group_code: [
          v => !!v || "Group code is required",
          v =>
            new RegExp(/^[A-Z0-9]{5}$/).test(v) ||
            "Group code must only contain 5 alphanumeric characters in uppercase"
        ]
      },
      search: null,
      model: null,
      instituteLoading: false,
      loading: false
    };
  },
  computed: {
    verb() {
      return this.$route.params.id ? "Update" : "Create";
    },
    pastVerb() {
      return `${this.verb}d`;
    },
    dataId() {
      return this.$route.params.id;
    }
  },
  watch: {
    search(val) {
      val && val !== this.form.institute_id && this.searchInstitutes(val);
    }
  },
  async created() {
    this.setNavbarTitle("Manage Group");
    if (this.dataId) {
      const group = await Group.find(this.dataId);
      const { name, grade, groupCode: group_code, institute } = group.data.data;
      this.form = { name, grade, group_code };
      this.institutes.push(institute);
      this.form.institute_id = institute.id;
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async searchInstitutes(query) {
      this.instituteLoading = true;
      const institutes = await Institute.search(query);
      this.institutes = institutes.data.data.institutes;
      this.instituteLoading = false;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          if (this.dataId) {
            await Group.update(this.dataId, this.form);
          } else {
            await Group.create(this.form);
          }

          this.$snackbar.showMessage({
            content: `Data ${this.pastVerb.toLowerCase()}`,
            variant: "success"
          });
          this.$router.push({ name: "Group.Index" });
        } catch (error) {
          this.loading = false;
        }
      }
    }
  }
};
</script>
